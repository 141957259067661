import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import axios from 'axios';
var url = new URL(window.location.href);
var baseURL = decodeURIComponent(url.searchParams.get('host')) || 'dev.aimecast.com';

if (!/^https?:\/\//i.test(baseURL)) {
  baseURL = "https://".concat(baseURL);
}

baseURL = "".concat(baseURL, "/api");
var instance = axios.create({
  baseURL: baseURL
});
var api = {
  search: function search(_ref) {
    var session = _ref.session,
        params = _objectWithoutProperties(_ref, ["session"]);

    return instance('/user/search', {
      params: params,
      headers: {
        'Icm-Token': session
      }
    });
  },
  invite: function invite(_ref2) {
    var session = _ref2.session,
        params = _objectWithoutProperties(_ref2, ["session"]);

    return instance.post('/node/sendJoinRoomInvitation', null, {
      params: params,
      headers: {
        'Icm-Token': session
      }
    });
  }
};
export default api;