// getUserMedia helper by @HenrikJoreteg used for navigator.getUserMedia shim
// https://github.com/otalk/getUserMedia/releases/tag/2.0.1
var adapter = require('webrtc-adapter');

module.exports = function (constraints, cb) {
  var error;
  var haveOpts = arguments.length === 2;
  var defaultOpts = {
    video: true,
    audio: true
  };
  var denied = 'PermissionDeniedError';
  var altDenied = 'PERMISSION_DENIED';
  var notSatisfied = 'ConstraintNotSatisfiedError'; // make constraints optional

  if (!haveOpts) {
    cb = constraints;
    constraints = defaultOpts;
  } // Safari supports 'MediaDevices.getUserMedia()' now
  // which is used below, therefore it does not matter
  // that 'navigator.getUserMedia' is undefined


  if (typeof navigator === 'undefined') {
    // throw proper error per spec
    error = new Error('MediaStreamError');
    error.name = 'NotSupportedError'; // keep all callbacks async

    return setTimeout(function () {
      cb(error);
    }, 0);
  } // normalize error handling when no media types are requested


  if (!constraints.audio && !constraints.video) {
    error = new Error('MediaStreamError');
    error.name = 'NoMediaRequestedError'; // keep all callbacks async

    return setTimeout(function () {
      cb(error);
    }, 0);
  }

  constraints = {
    audio: {
      // autoGainControl: false,
      echoCancellation: true // noiseSuppression: true,

    },
    video: false
  };
  console.log('Updated constraints:', constraints);
  navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
    navigator.mediaDevices.enumerateDevices().then(function (devices) {
      console.log(devices);
    });
    console.log(stream);
    cb(null, stream);
  })["catch"](function (err) {
    var error;
    navigator.mediaDevices.enumerateDevices().then(function (devices) {
      console.log(devices);
    });
    console.error('00error: ', err); // coerce into an error object since FF gives us a string
    // there are only two valid names according to the spec
    // we coerce all non-denied to "constraint not satisfied".

    if (typeof err === 'string') {
      error = new Error('MediaStreamError');

      if (err === denied || err === altDenied) {
        error.name = denied;
      } else {
        error.name = notSatisfied;
      }
    } else {
      // if we get an error object make sure '.name' property is set
      // according to spec: http://dev.w3.org/2011/webrtc/editor/getusermedia.html#navigatorusermediaerror-and-navigatorusermediaerrorcallback
      error = err;

      if (!error.name) {
        // this is likely chrome which
        // sets a property called "ERROR_DENIED" on the error object
        // if so we make sure to set a name
        if (error[denied]) {
          err.name = denied;
        } else {
          err.name = notSatisfied;
        }
      }
    }

    cb(error);
  });
};